const botonAnterior = document.getElementById("prevBtn")
const botonSiguiente = document.getElementById("nextBtn")
const preguntas = document.getElementsByClassName("question")
const divPreguntas = document.getElementsByClassName("preguntas")[0]
const cerrar = document.getElementById("questionForm")
const formulario = document.getElementsByClassName("form")[0]
const numeroPreguntas = preguntas.length
const botonCotizar = document.getElementsByClassName("cotizar")[0]
const cerrarVentana = document.getElementsByClassName("cerrarVentana")[0]
const pregunta1 = document.getElementById("answer1")
const pregunta2 = document.getElementById("industria")
const nombre = document.getElementById("nombre")
const correo = document.getElementById("correo")
const numero = document.getElementById("numero")
const verificacion = document.getElementsByClassName("verificacion")
const botonEnviar = document.getElementById("enviarData")
var preguntaActual = 0
console.log(verificacion[0])
botonSiguiente.addEventListener("click",function(){
    preguntas[preguntaActual].classList.remove("active")
    preguntaActual++
    preguntas[preguntaActual].classList.add("active")
    if(preguntaActual == numeroPreguntas-1){
        botonSiguiente.style.display = "none"
        botonEnviar.style.display="unset"
    }
    else if(preguntaActual == 0){
        botonAnterior.style.display = "none"
        botonEnviar.style.display="none"
    }
    else{
        botonAnterior.style.display = "unset"
        botonEnviar.style.display="none"
    }
})
botonAnterior.addEventListener("click",function(){
    preguntas[preguntaActual].classList.remove("active")
    preguntaActual--
    preguntas[preguntaActual].classList.add("active")
    if(preguntaActual == 0){
        botonAnterior.style.display = "none"
        botonEnviar.style.display="none"
    }
    else if(preguntaActual == numeroPreguntas-1){
        botonSiguiente.style.display = "none"
        botonEnviar.style.display="unset"
    }
    else{
        botonSiguiente.style.display = "unset"
        botonEnviar.style.display="none"
    }
})
cerrarVentana.addEventListener("click",function(){
    formulario.style.display = "none"
})
botonCotizar.addEventListener("click",function(){
    formulario.style.display = "unset"
})
